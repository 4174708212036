import React from 'react';
import { graphql, PageProps } from 'gatsby';

import { getPrograms, getDefaultSEO, getFeaturedImageDataFromWpPost } from '../../utils';
import Seo from '../../components/Seo';
import { MAX_NUMBER_OF_POSTS_PER_PAGE, HT_DEFAULT_PAGE_NAME, HT_PAGE_CATEGORY_ARCHIVE_PAGE } from '../../constants';
import HotelPointsPrograms from '../../components/EarnAndUsePoints/HotelPointsPrograms';
import HTPageWrapper from '../../wrappers/HTPageWrapper';

interface IHotelPointsProgramsTemplateData {
  posts: Queries.WpPostConnection;
}

const HotelPointsProgramsTemplate = ({
  data,
  pageContext,
  location,
}: PageProps<IHotelPointsProgramsTemplateData>) => {
  const {
    posts: { nodes },
  } = data;
  const currentPageIndex = (pageContext as any)?.pageNumber - 1 || 0;
  let posts: Queries.WpPost[] = [];

  if (nodes && Array.isArray(nodes)) {
    posts = nodes;
  }

  const programs = getPrograms(posts);
  const totalNumberOfPages = (pageContext as any)?.totalNumberOfPages || 0;
  const title = 'Hotel Points Programs';

  return (
    <HTPageWrapper
      category={"Earn & Use Points, Hotels, " + HT_PAGE_CATEGORY_ARCHIVE_PAGE}
      title={title || HT_DEFAULT_PAGE_NAME}
      location={location}
    >
      <HotelPointsPrograms
        pageHeader={{
          title: title,
          breadCrumbs: [
            {
              link: '/',
              text: 'Home',
            },
            {
              link: '/earn-use-points/',
              text: 'Earn & Use Points',
            },
            {
              link: '/earn-use-points/hotels/',
              text: 'Hotel Points Programs',
            },
          ],
          subTitle:
            'Browse our expert guides on earning and spending hotel points, to upgrade your next getaway.',
        }}
        programs={{
          programs,
        }}
        pagination={{
          currentPageIndex,
          pageSize: MAX_NUMBER_OF_POSTS_PER_PAGE,
          basePath: '/earn-use-points/hotels',
          numberOfPages: totalNumberOfPages,
        }}
      />
    </HTPageWrapper>
  );
};

export default HotelPointsProgramsTemplate;

export const Head = ({ location, data }: PageProps<IHotelPointsProgramsTemplateData>) => {
  const pageTitle = 'Hotel Points Programs | UpgradedPoints.com';
  const socialTitle = pageTitle;
  const ogDescription =
    'Get the best discounts and perks with our expert guides on hotel points programs. Learn how to earn the most points and find new ways to redeem them. ';
  const includeCollectionPageType = true;
  let pathname = location.pathname;
  if (!pathname.endsWith('/')) pathname += '/';
  const pageNumber = pathname.includes('page') ? parseInt(pathname.slice(-2, -1)) : 1;
  const extraBreadcrumbList = [
    { name: 'Earn & Use Points', slug: 'earn-use-points' },
    { name: 'Hotel Points', slug: 'hotels' },
  ];
  if (pageNumber > 1) {
    extraBreadcrumbList.push({
      name: 'Hotel Points Programs - Page ' + pageNumber,
      slug: 'page/' + pageNumber,
    });
  }
  const seo = getDefaultSEO(
    location.pathname,
    pageTitle,
    socialTitle,
    ogDescription,
    includeCollectionPageType,
    extraBreadcrumbList,
  );
  const firstPost = data?.posts?.nodes[0];
  const featuredImageData = firstPost ? getFeaturedImageDataFromWpPost(firstPost) : null;
  return (
    <Seo
      title={pageTitle}
      wpSeo={seo}
      featuredImageData={featuredImageData}
      pageNumber={pageNumber}
    />
  );
};

export const query = graphql`
  query HotelPointsPrograms($start: Int, $tagName: String) {
    posts: allWpPost(
      filter: { tags: { nodes: { elemMatch: { name: { regex: $tagName } } } } }
      limit: 21
      skip: $start
      sort: { date: DESC }
    ) {
      nodes {
        title
        uri
        featuredImage {
          node {
            caption
            width
            height
            sourceUrl
          }
        }
      }
    }
  }
`;

import React, { FC } from 'react';
import { IHotelPointsPrograms } from './HotelPointsPrograms.def';
import PageHeader from '../../PageHeader/PageHeader';
import PaginationV2 from '../../PaginationV2/PaginationV2';
import Programs from '../../Programs/Programs';

const HotelPointsPrograms: FC<IHotelPointsPrograms> = ({ pageHeader, pagination, programs }) => {
  return (
    <div className="hotel-points-programs">
      <PageHeader {...pageHeader} />
      <div className="container">
        <Programs {...programs} />
        <PaginationV2 {...pagination} />
      </div>
    </div>
  );
};

export default HotelPointsPrograms;
